import React from "react";
import css from "./Footer.module.css";
import Logo from "../../assets/logo.png";
import {
  InboxIcon,
  PhoneIcon,
  LocationMarkerIcon,
  LoginIcon,
  UserIcon,
  LinkIcon,
  HomeIcon,
} from "@heroicons/react/outline";

const Footer = () => {
  return (
    <div className={css.cFooterwWrapper}>
      <hr />
      <div className={css.cFooter}>
        <div className={css.logo}>
          <img src={Logo} alt="" />
          <span>Zk-products</span>
        </div>
        <div className={css.block}>
          <div className={css.detail}>
            <span>Contact Us</span>
            <span className={css.pngLine}>
              <LocationMarkerIcon className={css.icon} />
              <span>111 north Avenue Orlando , RC 45012</span>
            </span>
            <span className={css.pngLine}>
              <InboxIcon className={css.icon} />
              <a>support@zkproducts.com</a>
            </span>
            <span className={css.pngLine}>
              <PhoneIcon className={css.icon} />
              <a>03149087890</a>
            </span>
          </div>
        </div>

        <div className={css.block}>
          <div className={css.detail}>
            <span>Account</span>
            <span className={css.pngLine}>
              <LoginIcon className={css.icon} />
              <span>Sign In</span>
            </span>
          </div>
        </div>

        <div className={css.block}>
          <div className={css.detail}>
            <span>Company</span>
            <span className={css.pngLine}>
              <UserIcon className={css.icon} />
              <a>About Us</a>
            </span>
          </div>
        </div>

        <div className={css.block}>
          <div className={css.detail}>
            <span>Resources</span>
            <span className={css.pngLine}>
              <LinkIcon className={css.icon} />
              <span>Safety Privacy & Terms</span>
            </span>
          </div>
        </div>
      </div>
      <div className={css.copyright}>
        <span>CopyRight @2023 by zk-products , Inc</span>
        <span>All Right Reserved.</span>
      </div>
    </div>
  );
};

export default Footer;
